import { ref } from '#imports'

/**
 * Composable function for managing sign-in and sign-up popup properties.
 * Handles the display and properties of various popups based on the user's authentication status.
 */
export const useSignInPopupProps = () => {
  const $i18n = useI18n()
  const isSignUpPopupVisible = ref(false)
  const authStore = useAuthStore()

  /**
   * Defines properties for different types of sign-up popups.
   */
  const signUpPopupProps = ref({
    wordList: {
      title: $i18n.t('signUpWordListsTitle'),
      subtitle: $i18n.t('signUpSubtitle'),
      img: '/wordlist-login-popup.svg'
    },
    students: {
      title: $i18n.t('signUpStudentsTitle'),
      subtitle: $i18n.t('signUpSubtitle'),
      img: '/students-login-popup.svg'
    },
    lesson: {
      title: $i18n.t('signUpLessonTitle'),
      subtitle: $i18n.t('signUpSubtitle'),
      img: '/lesson-login-popup.svg'
    },
    exercises: {
      title: $i18n.t('signUpExercisesTitle'),
      subtitle: $i18n.t('signUpSubtitle'),
      img: '/exercises-login-popup.svg'
    }
  })

  /**
   * Ref to track the current type of popup being displayed or interacted with.
   */
  const currentPopupType = ref<keyof typeof signUpPopupProps.value>('wordList')

  /**
   * Computed property to get the current popup's properties based on `currentPopupType`.
   */
  const currentPopup = computed(() => signUpPopupProps.value[currentPopupType.value || 'wordList'])

  /**
   * Handles clicks on elements that require authentication.
   * Shows the sign-up popup if the user is a guest, otherwise executes the provided function.
   * @param type - The type of popup to show if the user is a guest.
   * @param fn - Optional function to execute if the user is not a guest.
   */
  const onProtectedClick = (type: keyof typeof signUpPopupProps.value, fn?:Function) => {
    if (authStore.isGuest) {
      currentPopupType.value = type
      isSignUpPopupVisible.value = true
    } else if (fn) {
      fn()
    }
  }
  return {
    signUpPopupProps,
    currentPopupType,
    currentPopup,
    isSignUpPopupVisible,
    onProtectedClick
  }
}
